import React from 'react'

const NotFoundPage = () =>
	<div className="notfoundpage">
		<div className="container">
			<h1>NOT FOUND</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</div>
	</div>

export default NotFoundPage
